import { Web3ContextProvider } from "@nftstudios/web3-provider";
import Layout from "./components/Layout";
import { Toaster } from "react-hot-toast";
import {
  INFURA_ID,
  WALLET_CONNECT_CHAIN,
  WALLET_CONNECT_ID,
} from "./config/env";
import "./App.scss";

const App = () => {
  return (
    <Web3ContextProvider
      infuraId={INFURA_ID}
      projectId={WALLET_CONNECT_ID}
      chainIds={[WALLET_CONNECT_CHAIN]}
      appName="Wallet Signer"
      appLogoUrl="https://myapp.com/mylogo.png"
      darkMode={false}
    >
      <Toaster toastOptions={{ duration: 1000 }} />
      <Layout />
    </Web3ContextProvider>
  );
};

export default App;
