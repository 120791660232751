import { useWeb3Context, WalletProviders } from "@nftstudios/web3-provider";
import axios from "axios";
import { FormEvent, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { API_BASE_URL } from "../../config/env";

const Home = () => {
  const { walletAddress, connect, signMessage } = useWeb3Context();
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uuid, setUuid] = useState("");

  const login = async () => {
    try {
      await connect(WalletProviders.MetaMask);
    } catch (_) {
      await connect(WalletProviders.WalletConnectV2);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!walletAddress || !messageRef.current) {
      return;
    }
    const message = messageRef.current.value;
    const signature = await signMessage(message);

    setIsLoading(true);

    axios
      .post(
        "/signatures",
        { walletAddress, signature, message },
        { baseURL: API_BASE_URL }
      )
      .then(({ data }) => setUuid(data.uuid))
      .finally(() => setIsLoading(false));
  };

  if (uuid) {
    return <Navigate to={`/${uuid}`} />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="message">Message:</label>
        <div>
          <textarea
            id="message"
            rows={10}
            ref={messageRef}
            placeholder="Paste here the message to be signed"
            required
          />
        </div>
        {walletAddress !== "" ? (
          <button>Sign</button>
        ) : (
          <button type="button" onClick={login}>
            Connect Wallet
          </button>
        )}
      </form>
    </div>
  );
};

export default Home;
