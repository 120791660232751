import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/env";
import styles from "./SignedMessage.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useReactToPrint } from "react-to-print";

const SignedMessage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [message, setMessage] = useState("");
  const [signature, setSignature] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `${uuid}.pdf`,
  });

  useEffect(() => {
    axios
      .get(`/signatures/${uuid}`, { baseURL: API_BASE_URL })
      .then(({ data }) => {
        setMessage(data.message);
        setSignature(data.signature);
        setWalletAddress(data.walletAddress);
        setIsLoading(false);
      })
      .catch(() => setNotFound(true));
  }, [uuid]);

  if (notFound) {
    return <Navigate to="/" replace />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.signedMessage}>
      <div ref={ref} className={styles.container}>
        <label htmlFor="message">Message:</label>
        <div>
          <textarea id="message" rows={10} readOnly value={message} />
        </div>
        <label htmlFor="signedBy">Signed by:</label>
        <input id="signedBy" type="text" readOnly value={walletAddress} />
        <label htmlFor="signature">Signature:</label>
        <div>
          <textarea id="signature" rows={3} readOnly value={signature} />
        </div>
        <div className={styles.certifies}>
          <p className={styles.bullet}>⬤</p>
          <p>
            This report page certifies that the wallet <b>{walletAddress}</b>{" "}
            has signed with its private key the given message.
          </p>
        </div>
      </div>
      <div>
        <CopyToClipboard
          text={window.location.href}
          onCopy={() => toast("Copied to clipboard")}
        >
          <button className="link">Copy Report Link</button>
        </CopyToClipboard>

        <button className="link" onClick={handlePrint}>
          Download Report PDF
        </button>
      </div>
    </div>
  );
};

export default SignedMessage;
