import { useWeb3Context } from "@nftstudios/web3-provider";
import { Link } from "react-router-dom";
import styles from "./NavBar.module.scss";

const NavBar = () => {
  const { walletAddress } = useWeb3Context();

  return (
    <div className={styles.navBar}>
      <ul className={styles.menu}>
        <li className={styles.walletSignature}>
          <Link to="/">Wallet Signature</Link>
        </li>
        <li>
          {walletAddress.substring(0, 6)}...{walletAddress.substring(38, 42)}
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
